import { Heading } from 'components/Basic/Heading/Heading';
import { ProductsSlider } from 'components/Blocks/Product/ProductsSlider';
import { useTypedTranslationFunction } from 'hooks/typescript/useTypedTranslationFunction';
import { FC } from 'react';
import { SliderProductItemType } from 'types/product';

type ProductDetailRelatedProps = {
    relatedProducts: SliderProductItemType[];
};

export const ProductDetailRelated: FC<ProductDetailRelatedProps> = ({ relatedProducts }) => {
    const t = useTypedTranslationFunction();

    if (relatedProducts.length === 0) {
        return null;
    }

    return (
        <div>
            <Heading type="h2">{t('Additional products and accessories')}</Heading>
            <ProductsSlider products={relatedProducts} gtmListName="related" />
        </div>
    );
};
