import { Loader } from 'components/Basic/Loader/Loader';
import { AddToCartPopup } from 'components/Blocks/Product/AddToCartPopup/AddToCartPopup';
import { ProductPrice } from 'components/Blocks/Product/Price/ProductPrice';
import { Button } from 'components/Forms/Button/Button';
import { Spinbox } from 'components/Forms/Spinbox/Spinbox';
import { mapAddToCartPopupData } from 'connectors/cart/Cart';
import { useAddToCart } from 'hooks/cart/useAddToCart';
import { useTypedTranslationFunction } from 'hooks/typescript/useTypedTranslationFunction';
import { FC, useRef, useState } from 'react';
import { useShopsysSelector } from 'redux/main';
import { twJoin } from 'tailwind-merge';
import { AddToCartPopupDataType } from 'types/cart';
import { ProductDetailType } from 'types/product';

type ProductDetailAddToCartProps = {
    product: ProductDetailType;
};

const TEST_IDENTIFIER = 'pages-productdetail-addtocart';

export const ProductDetailAddToCart: FC<ProductDetailAddToCartProps> = ({ product }) => {
    const spinboxRef = useRef<HTMLInputElement | null>(null);
    const t = useTypedTranslationFunction();
    const [changeCartItemQuantity, fetching] = useAddToCart('product');
    const [popupData, setPopupData] = useState<AddToCartPopupDataType | null>(null);
    const { currencyCode } = useShopsysSelector((state) => state.domain);

    const onAddToCartHandler = async () => {
        if (spinboxRef.current === null) {
            return;
        }

        const addToCartResult = await changeCartItemQuantity(product.uuid, spinboxRef.current.valueAsNumber, 'detail');
        spinboxRef.current!.valueAsNumber = product.minimumPurchaseQuantity;
        setPopupData(mapAddToCartPopupData(addToCartResult, currencyCode));
    };

    return (
        <>
            <div
                className="my-5 mb-4 flex flex-col gap-2 rounded-xl bg-white p-5 lg:mb-3 vl:gap-4"
                data-testid={TEST_IDENTIFIER}
            >
                <ProductPrice
                    productPrice={product.price}
                    quantity={product.minimumPurchaseQuantity}
                    className="flex w-fit [&>div]:text-2xl [&>div]:font-bold"
                    withUnitsInfo
                />
                <div
                    className={twJoin(
                        'flex items-center font-bold no-underline hover:no-underline vl:hidden',
                        product.availability.status === 'in-stock' && 'text-inStock hover:text-inStock',
                        product.availability.status === 'out-of-stock' && 'text-red hover:text-red',
                    )}
                >
                    {product.availability.name}
                </div>
                {product.isSellingDenied ? (
                    <p>{t('This item can no longer be purchased')}</p>
                ) : (
                    <div className="text-sm vl:text-base">
                        <div className="flex items-center justify-between">
                            <Spinbox
                                min={product.minimumPurchaseQuantity}
                                step={product.minimumPurchaseQuantity}
                                defaultValue={product.minimumPurchaseQuantity}
                                max={product.stockQuantity}
                                ref={spinboxRef}
                            />
                            <div className="ml-2 flex-1">
                                <Button
                                    isDisabled={product.isSellingDenied}
                                    className="w-full rounded-md px-0 text-base font-bold uppercase"
                                    onClick={onAddToCartHandler}
                                    variant="primary"
                                    data-testid={TEST_IDENTIFIER + '-button'}
                                >
                                    {fetching && <Loader className="h-6 w-6" />}
                                    {t('Add to cart')}
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {popupData !== null && (
                <AddToCartPopup isVisible onCloseCallback={() => setPopupData(null)} product={popupData} />
            )}
        </>
    );
};
