import { AdvancedSeoCategoriesSlider } from './AdvancedSeoCategoriesSlider/AdvancedSeoCategoriesSlider';
import { Heading } from 'components/Basic/Heading/Heading';
import { isElementVisible } from 'components/Helpers/isElementVisible';
import { desktopFirstSizes } from 'components/Theme/mediaQueries';
import { useTypedTranslationFunction } from 'hooks/typescript/useTypedTranslationFunction';
import { useGetWindowSize } from 'hooks/ui/useGetWindowSize';
import { useResizeWidthEffect } from 'hooks/ui/useResizeWidthEffect';
import 'keen-slider/keen-slider.min.css';
import NextLink from 'next/link';
import { FC, useState } from 'react';
import { ReadyCategorySeoMixLink } from 'types/category';

type AdvancedSeoCategoriesProps = {
    readyCategorySeoMixLinks: ReadyCategorySeoMixLink[];
};

export const advancedSeoCategoriesItemTwClass =
    'flex items-center justify-center rounded-xl bg-white p-3 text-center text-sm leading-4 no-underline hover:bg-whitesmoke transition hover:no-underline lg:mb-3 lg:mr-6 lg:px-4';

export const AdvancedSeoCategories: FC<AdvancedSeoCategoriesProps> = ({ readyCategorySeoMixLinks }) => {
    const t = useTypedTranslationFunction();
    const { width } = useGetWindowSize();
    const [isAdvancedSeoCategoriesSliderVisible, setAdvancedSeoCategoriesSliderVisibility] = useState(true);
    useResizeWidthEffect(
        width,
        desktopFirstSizes.tablet,
        () => setAdvancedSeoCategoriesSliderVisibility(false),
        () => setAdvancedSeoCategoriesSliderVisibility(true),
        () => setAdvancedSeoCategoriesSliderVisibility(isElementVisible([{ min: 0, max: 768 }], width)),
    );

    if (readyCategorySeoMixLinks.length === 0) {
        return null;
    }

    return (
        <>
            <Heading className="text-lg" type="h3">
                {t('Favorite')}
            </Heading>
            {isAdvancedSeoCategoriesSliderVisible ? (
                <AdvancedSeoCategoriesSlider readyCategorySeoMixLinks={readyCategorySeoMixLinks} />
            ) : (
                <div className="-mr-6 flex flex-row flex-wrap">
                    {readyCategorySeoMixLinks.map((seoMixLink, index) => (
                        <NextLink key={index} href={seoMixLink.slug} passHref>
                            <a className={advancedSeoCategoriesItemTwClass}>{seoMixLink.name}</a>
                        </NextLink>
                    ))}
                </div>
            )}
        </>
    );
};
