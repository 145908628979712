import { Icon } from 'components/Basic/Icon/Icon';
import { ProductFlags } from 'components/Blocks/Product/Flags/ProductFlags';
import { theme } from 'components/Theme/main';
import 'keen-slider/keen-slider.min.css';
import { useKeenSlider } from 'keen-slider/react';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import LightGallery from 'lightgallery/react';
import { FC, useState } from 'react';
import { twJoin } from 'tailwind-merge';
import { SimpleFlagType } from 'types/flag';
import { ImageType } from 'types/image';

type ProductDetailImageSliderProps = {
    galleryItems: ImageType[];
    flags: SimpleFlagType[];
};

export const ProductDetailImageSlider: FC<ProductDetailImageSliderProps> = ({ galleryItems, flags }) => {
    const [areControlsVisible, setAreControlsVisible] = useState<boolean | undefined>(false);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
        loop: false,
        duration: 1000,
        breakpoints: {
            [theme.mediaQueries.queryTablet]: {
                slidesPerView: 1,
                spacing: 0,
            },
        },
        slideChanged(slider) {
            setCurrentSlide(slider.details().relativeSlide);
        },
        move(slider) {
            setAreControlsVisible(slider.options().controls);
        },
    });

    const onMoveToNextSlideHandler = () => {
        slider.moveToSlide(currentSlide + 1);
    };

    const onMoveToPreviousSlideHandler = () => {
        slider.moveToSlide(currentSlide - 1);
    };

    return (
        <LightGallery mode="lg-fade" thumbnail plugins={[lgThumbnail]} selector=".lightboxItem">
            <div className="relative flex w-full flex-col pb-0">
                <div ref={sliderRef} className="keen-slider w-full cursor-pointer vl:w-[calc(100%-307px)] ">
                    {galleryItems.map((galleryItem, index) => (
                        <div
                            key={index}
                            className="keen-slider__slide lightboxItem flex max-h-[250px] min-h-[250px] w-full items-center justify-center py-4 sm:max-h-[300px] sm:min-h-[300px] md:max-h-[330px] md:min-h-[330px]"
                            data-src={galleryItem.sizes?.find((size) => size.size === 'default')?.url}
                        >
                            <img
                                className="!max-w-44 lg:!max-w-64 !max-h-44 lg:!max-h-64"
                                loading="lazy"
                                src={galleryItem.sizes?.find((size) => size.size === 'default')?.url}
                            />
                        </div>
                    ))}
                </div>
                {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
                {slider !== null && areControlsVisible ? (
                    <>
                        <ImageSliderControl onClick={onMoveToPreviousSlideHandler} isDisabled={currentSlide === 0}>
                            <Icon icon="Arrow" className="h-3 w-3 rotate-90" iconType="icon" />
                        </ImageSliderControl>
                        <ImageSliderControl
                            onClick={onMoveToNextSlideHandler}
                            isNext
                            isDisabled={currentSlide === galleryItems.length - 1}
                        >
                            <Icon icon="Arrow" className="h-3 w-3 rotate-[270deg]" iconType="icon" />
                        </ImageSliderControl>
                    </>
                ) : null}
                <div className="absolute left-4 top-3 flex flex-col">
                    <ProductFlags flags={flags} />
                </div>
            </div>
        </LightGallery>
    );
};

type ImageSliderControlProps = {
    isNext?: boolean;
    onClick: () => void;
    isDisabled?: boolean;
};

const ImageSliderControl: FC<ImageSliderControlProps> = ({ children, isNext, onClick, isDisabled }) => (
    <button
        className={twJoin(
            'absolute top-[calc(50%-16px)] flex h-8 w-8 cursor-pointer items-center justify-center rounded-sm border-none bg-greyDark text-creamWhite outline-none transition hover:bg-greyDarker',
            isNext ? 'right-0' : 'left-0',
            isDisabled && 'bg-greyLight',
        )}
        onClick={onClick}
    >
        {children}
    </button>
);
