import { Maybe } from 'graphql/generated';
import { getGtmProductDetailEvent, getNewGtmEcommerceEvent } from 'helpers/gtm/eventFactories';
import { gtmSafePushEvent } from 'helpers/gtm/gtm';
import { useEffect, useRef } from 'react';
import { useShopsysSelector } from 'redux/main';
import { FriendlyUrlPageType } from 'types/friendlyUrl';

export const useGtmProductDetailView = (
    data: Maybe<FriendlyUrlPageType> | undefined,
    slug: string,
    fetching: boolean,
): void => {
    const event = getNewGtmEcommerceEvent('ec.product_view', true);
    const currencyCode = useShopsysSelector((state) => state.domain.currencyCode);
    const lastViewedProductDetailSlug = useRef<string | undefined>(undefined);
    const { url } = useShopsysSelector((state) => state.domain);

    useEffect(() => {
        if (
            data !== null &&
            data !== undefined &&
            (data.__typename === 'MainVariant' ||
                data.__typename === 'RegularProduct' ||
                data.__typename === 'Variant') &&
            lastViewedProductDetailSlug.current !== slug &&
            !fetching
        ) {
            lastViewedProductDetailSlug.current = slug;
            event.ecommerce = getGtmProductDetailEvent(data, currencyCode, url);
            gtmSafePushEvent(event);
        }
    }, [data, currencyCode, event, slug, url, fetching]);
};
