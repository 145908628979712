import { ProductDetailAccessories } from './ProductDetailAccessories/ProductDetailAccessories';
import { ProductDetailAddToCart } from './ProductDetailAddToCart/ProductDetailAddToCart';
import {
    ProductDetail,
    ProductDetailCode,
    ProductDetailHeading,
    ProductDetailImage,
    ProductDetailInfo,
    ProductDetailPrefix,
} from './ProductDetailElements';
import { ProductDetailGallery } from './ProductDetailGallery';
import { ProductDetailRelated } from './ProductDetailRelated/ProductDetailRelated';
import { ProductMetadata } from 'components/Basic/Head/ProductMetadata/ProductMetadata';
import { Heading } from 'components/Basic/Heading/Heading';
import { Table } from 'components/Basic/Table/Table';
import { UserText } from 'components/Helpers/UserText/UserText';
import { Webline } from 'components/Layout/Webline/Webline';
import { getUrlWithoutGetParameters } from 'helpers/parsing/getUrlWithoutGetParameters';
import { useGtmProductDetailView } from 'hooks/gtm/useGtmProductDetailView';
import { useTypedTranslationFunction } from 'hooks/typescript/useTypedTranslationFunction';
import Trans from 'next-translate/Trans';
import { useRouter } from 'next/router';
import { FC, Fragment } from 'react';
import { ProductDetailType } from 'types/product';

type ProductDetailContentProps = {
    product: ProductDetailType;
    fetching: boolean;
};

const TEST_IDENTIFIER = 'pages-productdetail-';

export const ProductDetailContent: FC<ProductDetailContentProps> = ({ product, fetching }) => {
    const t = useTypedTranslationFunction();
    const router = useRouter();
    useGtmProductDetailView(product, getUrlWithoutGetParameters(router.asPath), fetching);

    const formatParameterValue = (valueText: string, index: number) => {
        return index > 0 ? ' | ' + valueText : valueText;
    };

    return (
        // the key helps to re-mount the component when navigating between different products, which prevents the components from keeping an unwanted state
        <Fragment key={product.uuid}>
            <ProductMetadata product={product} />
            <Webline>
                <ProductDetail>
                    <ProductDetailImage>
                        <ProductDetailGallery
                            flags={product.flags}
                            images={product.images}
                            productName={product.name}
                        />
                    </ProductDetailImage>
                    <ProductDetailInfo>
                        <ProductDetailPrefix dataTestId={TEST_IDENTIFIER + 'prefix'}>
                            {product.namePrefix}
                        </ProductDetailPrefix>
                        <ProductDetailHeading dataTestId={TEST_IDENTIFIER + 'name'}>
                            {product.name} {product.nameSuffix}
                        </ProductDetailHeading>
                        <ProductDetailCode dataTestId={TEST_IDENTIFIER + 'code'}>
                            {t('Code')}: {product.catalogNumber}
                        </ProductDetailCode>
                        <div className="mb-5 hidden vl:block" data-testid={TEST_IDENTIFIER + 'short-description'}>
                            {product.shortDescription}
                        </div>
                        {product.isAvailableAtJafholzEshop && (
                            <div className="mb-3 rounded-lg bg-orangeLight p-2">
                                <Trans
                                    i18nKey="This product is available at the <link>JAF HOLZ Online Shop</link>"
                                    components={{
                                        link: (
                                            <a href={product.jafholzEshopUrl ?? '/'} target="_blank" rel="noreferrer" />
                                        ),
                                    }}
                                />
                            </div>
                        )}
                        <ProductDetailAddToCart product={product} />
                        {product.jafholzEshopUrl && (
                            <a href={product.jafholzEshopUrl} target="_blank" rel="noreferrer">
                                {t('Go to JAFHOLZ e-shop')}
                            </a>
                        )}
                        {product.staticFeaturesAttributesDescription && (
                            <UserText htmlContent={product.staticFeaturesAttributesDescription} />
                        )}
                    </ProductDetailInfo>
                </ProductDetail>
            </Webline>
            <Webline dataTestId={TEST_IDENTIFIER + 'description'} innerClassName="flex flex-col vl:flex-row gap-6">
                <div data-testid={TEST_IDENTIFIER + 'overview-content'}>
                    <UserText htmlContent={product.description} />
                </div>
                {product.parameters.length > 0 && (
                    <div data-testid={TEST_IDENTIFIER + 'parameters-content'} className="vl:flex-[0_0_40%]">
                        <Heading type="h2">{t('Parameters')}</Heading>
                        <Table>
                            <tbody>
                                {product.parameters.map((parameter) => (
                                    <tr
                                        key={parameter.uuid}
                                        className="!border-t-0 vl:!border-t vl:!border-greyLighter first:vl:!border-t-0"
                                    >
                                        <th className="py-2 text-left text-sm font-bold uppercase leading-5">
                                            {parameter.name}
                                        </th>
                                        <td className="w-2/3 py-2 text-right text-sm leading-5">
                                            {parameter.values.map((value, index) =>
                                                formatParameterValue(value.text, index),
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                )}
            </Webline>
            <Webline innerClassName="flex flex-col gap-6 mt-6">
                <ProductDetailAccessories accessories={product.accessories} />
            </Webline>
            <Webline innerClassName="flex flex-col gap-6 mt-8">
                <ProductDetailRelated relatedProducts={product.relatedProducts} />
            </Webline>
        </Fragment>
    );
};
