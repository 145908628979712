export const roundUpByStep = (
    numberToRound: number,
    step: number,
    allowedSteps: number[],
    maximum?: number | undefined,
) => {
    let roundedNumber = 0;

    if (allowedSteps.includes(numberToRound)) {
        roundedNumber = numberToRound;
    } else {
        roundedNumber = Math.ceil(numberToRound / step) * step;
    }

    return maximum && roundedNumber > maximum ? maximum : roundedNumber;
};
