import { SpinboxButtonStyled, SpinboxInputStyled, SpinboxSmallStyled, SpinboxStyled } from './Spinbox.style';
import { roundUpByStep } from 'helpers/roundUpByStep';
import { useForwardedRef } from 'hooks/typescript/useForwardedRef';
import { FormEventHandler, forwardRef, useCallback, useEffect, useRef, useState } from 'react';

type SpinboxProps = {
    min: number;
    max: number;
    step: number;
    defaultValue: number;
    onChangeValueCallback?: (currentValue: number) => void;
    size?: 'default' | 'small';
};

const TEST_IDENTIFIER = 'forms-spinbox-';

export const Spinbox = forwardRef<HTMLInputElement, SpinboxProps>(
    ({ min, max, onChangeValueCallback, step, defaultValue, size }, spinboxForwardedRef) => {
        const [isHoldingDecrease, setIsHoldingDecrease] = useState(false);
        const [isHoldingIncrease, setIsHoldingIncrease] = useState(false);
        const intervalRef = useRef<NodeJS.Timer | null>(null);
        const spinboxRef = useForwardedRef(spinboxForwardedRef);

        const setNewSpinboxValue = useCallback(
            (newValue: number) => {
                if (Number.isNaN(newValue) || newValue < min) {
                    spinboxRef.current.valueAsNumber = min;
                } else if (newValue > max) {
                    spinboxRef.current.valueAsNumber = max;
                } else {
                    spinboxRef.current.valueAsNumber = newValue;
                }

                if (onChangeValueCallback !== undefined) {
                    onChangeValueCallback(spinboxRef.current.valueAsNumber);
                }
            },
            [min, max, onChangeValueCallback, spinboxRef],
        );

        const onChangeValueHandler = useCallback(
            (amountChange: number) => {
                if (spinboxRef.current !== null) {
                    const roundedValue = roundUpByStep(spinboxRef.current.valueAsNumber + amountChange, step, [], max);
                    setNewSpinboxValue(roundedValue);
                }
            },
            [max, setNewSpinboxValue, spinboxRef, step],
        );

        useEffect(() => {
            if (isHoldingDecrease) {
                intervalRef.current = setInterval(() => {
                    onChangeValueHandler(-step);
                }, 200);
            } else {
                clearSpinboxInterval(intervalRef.current);
            }
            return () => {
                clearSpinboxInterval(intervalRef.current);
            };
        }, [isHoldingDecrease, onChangeValueHandler, step]);

        useEffect(() => {
            if (isHoldingIncrease) {
                intervalRef.current = setInterval(() => {
                    onChangeValueHandler(step);
                }, 200);
            } else {
                clearSpinboxInterval(intervalRef.current);
            }
            return () => {
                clearSpinboxInterval(intervalRef.current);
            };
        }, [isHoldingIncrease, onChangeValueHandler, step]);

        const clearSpinboxInterval = (interval: NodeJS.Timer | null) => {
            if (interval !== null) {
                clearInterval(interval);
            }
        };

        const onInputHandler: FormEventHandler<HTMLInputElement> = (event) => {
            if (spinboxRef.current !== null) {
                const roundedValue = roundUpByStep(event.currentTarget.valueAsNumber, step, [], max);

                setNewSpinboxValue(roundedValue);
            }
        };

        let Component = SpinboxStyled;

        if (size === 'small') {
            Component = SpinboxSmallStyled;
        }

        return (
            <Component>
                <SpinboxButtonStyled
                    onClick={() => onChangeValueHandler(-step)}
                    onMouseDown={() => setIsHoldingDecrease(true)}
                    onMouseUp={() => setIsHoldingDecrease(false)}
                    onMouseLeave={() => setIsHoldingDecrease(false)}
                    data-testid={TEST_IDENTIFIER + 'decrease'}
                >
                    -
                </SpinboxButtonStyled>
                <SpinboxInputStyled
                    ref={spinboxRef}
                    defaultValue={defaultValue}
                    onInput={onInputHandler}
                    type="number"
                    min={min}
                    max={max}
                    data-testid={TEST_IDENTIFIER + 'input'}
                />
                <SpinboxButtonStyled
                    onClick={() => onChangeValueHandler(step)}
                    onMouseDown={() => setIsHoldingIncrease(true)}
                    onMouseUp={() => setIsHoldingIncrease(false)}
                    onMouseLeave={() => setIsHoldingIncrease(false)}
                    data-testid={TEST_IDENTIFIER + 'increase'}
                >
                    +
                </SpinboxButtonStyled>
            </Component>
        );
    },
);

Spinbox.displayName = 'Spinbox';
