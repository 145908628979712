import { FilterState } from '../VariantsFilterContext/types';
import { ListedVariantType } from 'types/product';

export const filterVariantsBySelectedParameters = (
    variants: ListedVariantType[],
    state: FilterState,
): ListedVariantType[] => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return variants.filter((variant) => {
        let hasSelectVariant = true;
        const variantParameters = variant.parameters.filter((parameter) => parameter.isVariant);

        variantParameters.forEach((variantParameter) => {
            const selectedVariantParameter = state.selected.parameters.filter(
                (selectedVariantParameter) =>
                    selectedVariantParameter.selectedValue !== null &&
                    variantParameter.uuid === selectedVariantParameter.parameterUuid,
            );

            if (selectedVariantParameter.length === 0) {
                return;
            }

            const selectedVariantParameterValue = selectedVariantParameter[0].selectedValue;

            hasSelectVariant =
                hasSelectVariant &&
                variantParameter.values.filter(
                    (variantParameterValue) =>
                        variantParameterValue.uuid ===
                        selectedVariantParameter[0].values[selectedVariantParameterValue ?? 0].uuid,
                ).length > 0;
        });

        return hasSelectVariant;
    });
};
