import { twMergeCustom } from 'utils/twMerge';

export const ProductDetail: FC = ({ children }) => (
    <div className="mb-5 flex flex-col flex-wrap vl:flex-row">{children}</div>
);

export const ProductDetailImage: FC<{
    dataTestId?: string;
}> = ({ children, dataTestId }) => (
    <div
        className="mb-5 rounded-md bg-white vl:w-[calc(100%-512px)] vl:bg-transparent [&>div]:relative [&>div]:mb-5 [&>div]:flex [&>div]:w-full [&>div]:flex-row [&>div]:items-start [&>div]:justify-start [&>div]:overflow-hidden lg:[&>div]:rounded-xl"
        data-testid={dataTestId}
    >
        {children}
    </div>
);

export const ProductDetailInfo: FC = ({ children }) => <div className="w-full lg:mb-8 vl:max-w-lg">{children}</div>;

export const ProductDetailPrefix: FC = ({ children, dataTestId }) => (
    <div className="mb-1 hidden text-greyLight lg:block" data-testid={dataTestId}>
        {children}
    </div>
);

export const ProductDetailHeading: FC = ({ children, dataTestId }) => (
    <h1 className="mb-2 text-lg font-bold leading-5 text-black vl:text-2xl" data-testid={dataTestId}>
        {children}
    </h1>
);

export const ProductDetailCode: FC = ({ children, dataTestId, className }) => (
    <div className={twMergeCustom('mb-5 text-sm text-greyLight', className)} data-testid={dataTestId}>
        {children}
    </div>
);
