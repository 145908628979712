import { useVariantFilterState } from './VariantsFilterContext/useVariantFilterState';
import { Icon } from 'components/Basic/Icon/Icon';
import { Select } from 'components/Forms/Select/Select';
import { ParameterFragmentApi } from 'graphql/generated';
import { getUrlWithoutGetParameters } from 'helpers/parsing/getUrlWithoutGetParameters';
import { useTypedTranslationFunction } from 'hooks/typescript/useTypedTranslationFunction';
import { useRouter } from 'next/router';
import { FC, useEffect } from 'react';
import { ProductParameterType } from 'types/parameter';
import { ListedVariantType } from 'types/product';

type VariantsFilterProps = {
    variantParameters: ProductParameterType[];
    variants: ListedVariantType[];
    mainVariantSlugOfVariant: string | null | undefined;
};

export const VariantsFilter: FC<VariantsFilterProps> = ({ variantParameters, variants, mainVariantSlugOfVariant }) => {
    const t = useTypedTranslationFunction();
    const router = useRouter();
    const slug = getUrlWithoutGetParameters(router.asPath);
    const [variantFilterState, dispatch] = useVariantFilterState();

    const getSelectedParameterValues = (variantParameter: ProductParameterType) =>
        variantParameter.values
            .map((variantParameterValue) => ({
                value: variantParameterValue.uuid,
                label: variantParameterValue.text,
            }))
            .filter((variantParameterValue) => variantParameterValue.label !== '');

    const getSelectValue = (selectedValueIndex: number | null, parameterIndex: number) => {
        if (selectedValueIndex !== null) {
            return {
                value: variantFilterState.selected.parameters[parameterIndex].values[selectedValueIndex].uuid,
                label: variantFilterState.selected.parameters[parameterIndex].values[selectedValueIndex].text,
            };
        }

        return {
            value: null,
            label: t('-- Choose parameter --'),
        };
    };

    useEffect(() => {
        let defaultValue: undefined | ParameterFragmentApi;
        const defaultVariant = variants.find((variant) => variant.slug === slug);

        variantFilterState.options.parameters?.forEach((parameter, index) => {
            defaultValue = defaultVariant?.parameters.find((variantParameter) => {
                return variantParameter.name === parameter.name;
            });

            if (defaultValue) {
                dispatch({
                    payload: {
                        value: {
                            uuid: defaultValue.values[0].uuid,
                            text: defaultValue.values[0].uuid,
                        },
                        parameterIndex: index,
                    },
                    type: 'setVariantParameter',
                });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slug]);

    return (
        <div className="mb-5 grid grid-cols-2 gap-1 rounded-md bg-white p-3 lg:gap-3 lg:p-7">
            {variantParameters.map((variantParameter, index) => (
                <Select
                    key={index}
                    hasError={false}
                    label={variantParameter.name}
                    onChange={(data: any) => {
                        dispatch({
                            type: 'setVariantParameter',
                            payload: {
                                value: {
                                    uuid: data.value,
                                    text: data.label,
                                },
                                parameterIndex: index,
                            },
                        });
                    }}
                    options={getSelectedParameterValues(variantParameter)}
                    value={getSelectValue(variantFilterState.selected.parameters[index].selectedValue, index)}
                />
            ))}
            {variantParameters.length > 0 && (
                <button
                    type="reset"
                    name="reset-all-parameters"
                    className="mt-3 flex items-center gap-2 leading-none text-primary"
                    onClick={() => {
                        dispatch({
                            type: 'resetAllParameters',
                        });
                        if (mainVariantSlugOfVariant && !router.asPath.includes(mainVariantSlugOfVariant)) {
                            router.push(mainVariantSlugOfVariant);
                        }
                    }}
                >
                    <Icon icon="Close" iconType="icon" className="h-[10px] w-[10px]" />
                    <span className="text-sm underline">{t('Reset filters')}</span>
                </button>
            )}
        </div>
    );
};
