import { AdvancedSeoCategories } from './AdvancedSeoCategories/AdvancedSeoCategories';
import { CategoryDetailProductsWrapper } from './CategoryDetailProductsWrapper';
import { CategoryDetailSubcategories } from './CategoryDetailSubcategories';
import { MetaRobots } from 'components/Basic/Head/MetaRobots/MetaRobots';
import { Heading } from 'components/Basic/Heading/Heading';
import { Icon } from 'components/Basic/Icon/Icon';
import { Overlay } from 'components/Basic/Overlay/Overlay';
import { Adverts } from 'components/Blocks/Adverts/Adverts';
import { PaginationProvider } from 'components/Blocks/Pagination/PaginationProvider';
import { Filter } from 'components/Blocks/Product/Filter/Filter';
import { FilterProvider } from 'components/Blocks/Product/Filter/FilterContext/FilterProvider';
import { SelectedParameters } from 'components/Blocks/Product/Filter/SelectedParameters/SelectedParameters';
import { SortingBar } from 'components/Blocks/SortingBar/SortingBar';
import { Button } from 'components/Forms/Button/Button';
import LimitHeightWrap from 'components/Helpers/LimitHeightWrap/LimitHeightWrap';
import { Webline } from 'components/Layout/Webline/Webline';
import { getNewPagination } from 'helpers/pagination/getNewPagination';
import { parsePageNumberFromQuery } from 'helpers/pagination/parsePageNumberFromQuery';
import { PAGE_QUERY_PARAMETER_NAME } from 'helpers/queryParams/queryParamNames';
import { useTypedTranslationFunction } from 'hooks/typescript/useTypedTranslationFunction';
import { useMediaMin } from 'hooks/ui/useMediaMin';
import { useRouter } from 'next/router';
import { FC, useCallback, useRef, useState } from 'react';
import { twJoin } from 'tailwind-merge';
import { CategoryDetailType } from 'types/category';

type CategoryDetailContentProps = {
    category: CategoryDetailType;
};

export const CategoryDetailContent: FC<CategoryDetailContentProps> = ({ category }) => {
    const t = useTypedTranslationFunction();
    const isDesktop = useMediaMin('vl');
    const [isPanelOpen, setIsPanelOpen] = useState(false);
    const containerWrapRef = useRef<null | HTMLDivElement>(null);
    const { query } = useRouter();
    const isFiltered = 'filter' in query;
    const router = useRouter();
    const currentPage = parsePageNumberFromQuery(router.query[PAGE_QUERY_PARAMETER_NAME]);

    const handlePanelOpenerClick = useCallback(() => {
        const body = document.getElementsByTagName('body')[0];

        setIsPanelOpen((prev) => {
            const newValue = !prev;
            body.style.overflow = newValue ? 'hidden' : 'visible';

            return newValue;
        });
    }, []);
    if (category.productConnection.productFilterOptions === null) {
        return null;
    }

    return (
        <PaginationProvider key={category.uuid} {...getNewPagination(currentPage)}>
            <FilterProvider
                key={category.slug}
                originalSlug={category.originalCategorySlug}
                productFilterOptions={category.productConnection.productFilterOptions}
            >
                <Webline>
                    {isFiltered && <MetaRobots content="noindex, follow" />}
                    <div
                        className="relative mb-7 flex flex-col vl:mb-10 vl:flex-row vl:flex-wrap"
                        ref={containerWrapRef}
                    >
                        <div
                            className={twJoin(
                                'fixed bottom-0 left-0 right-10 top-0 max-w-[400px] -translate-x-full overflow-y-scroll transition max-vl:z-aboveOverlay vl:static vl:w-[304px] vl:translate-x-0 vl:overflow-auto vl:transition-none',
                                isPanelOpen && 'translate-x-0',
                            )}
                        >
                            <Filter
                                key={category.slug}
                                slug={category.slug}
                                originalSlug={category.originalCategorySlug}
                                orderingMode={category.productConnection.orderingMode}
                                defaultOrderingMode={category.productConnection.defaultOrderingMode}
                            />
                        </div>
                        <Overlay isActive={isPanelOpen} isHiddenOnDesktop onClick={handlePanelOpenerClick} />
                        <div className="flex flex-1 flex-col vl:pl-12">
                            <Adverts className="mb-4" positionName="productList" />
                            <Heading type={'h1'}>{category.seoH1 !== null ? category.seoH1 : category.name}</Heading>
                            {category.shortDescription && (
                                <div className="mb-4 hidden leading-7 lg:block">
                                    <LimitHeightWrap
                                        mobileHeight={56}
                                        desktopHeight={56}
                                        buttonLabelMore={t('Read on')}
                                        buttonLabelLess={t('Hide text')}
                                        buttonAlign="left"
                                        updateDependency={category.slug}
                                    >
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: category.shortDescription,
                                            }}
                                        />
                                    </LimitHeightWrap>
                                </div>
                            )}
                            <Adverts className="mb-4" positionName="productListMiddle" currentCategory={category} />
                            <CategoryDetailSubcategories
                                subCategories={[...category.children, ...category.linkedCategories]}
                            />
                            <AdvancedSeoCategories readyCategorySeoMixLinks={category.readyCategorySeoMixLinks} />
                            <div className="flex justify-between gap-2 vl:block">
                                <Button
                                    className={twJoin(
                                        'rounded-lg px-8 py-3 text-base font-bold uppercase leading-7 vl:hidden',
                                        isPanelOpen && 'z-overlay',
                                    )}
                                    onClick={handlePanelOpenerClick}
                                >
                                    <Icon
                                        className="mr-2 mt-0.5 h-6 w-6 font-bold text-white"
                                        iconType="icon"
                                        icon="Filter"
                                    />
                                    {t('Filter')}
                                </Button>
                                <SortingBar
                                    sorting={category.productConnection.orderingMode}
                                    totalCount={category.productConnection.totalCount}
                                />
                            </div>
                            {!isDesktop && <SelectedParameters className="mb-0 mt-5 vl:hidden " />}
                            <CategoryDetailProductsWrapper category={category} containerWrapRef={containerWrapRef} />
                            {category.description && (
                                <div className="mt-6 leading-6 lg:mt-14">
                                    <Heading type="h3" className="mb-1">
                                        {t('Properties')}
                                    </Heading>
                                    <LimitHeightWrap
                                        mobileHeight={220}
                                        desktopHeight={120}
                                        buttonLabelMore={t('Read on')}
                                        buttonLabelLess={t('Hide text')}
                                        buttonAlign="left"
                                        updateDependency={category.slug}
                                    >
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: category.description,
                                            }}
                                        />
                                    </LimitHeightWrap>
                                </div>
                            )}
                        </div>
                    </div>
                </Webline>
            </FilterProvider>
        </PaginationProvider>
    );
};
