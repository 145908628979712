import { Variant } from './Variant/Variant';
import { Heading } from 'components/Basic/Heading/Heading';
import { useVariantFilterState } from 'components/Blocks/Product/VariantsFilter/VariantsFilterContext/useVariantFilterState';
import { filterVariantsBySelectedParameters } from 'components/Blocks/Product/VariantsFilter/helpers/filterVariantsBySelectedParameters';
import { Button } from 'components/Forms/Button/Button';
import { useTypedTranslationFunction } from 'hooks/typescript/useTypedTranslationFunction';
import { FC, useState } from 'react';
import { ProductParameterType } from 'types/parameter';
import { ListedVariantType } from 'types/product';

const DEFAULT_NUMBER_OF_SHOWN_VARIANTS = 5;

type ProductVariantsTableProps = {
    variants: ListedVariantType[];
    isSellingDenied: boolean;
    variantsFilter: ProductParameterType[];
};

export const ProductVariantsTable: FC<ProductVariantsTableProps> = ({ isSellingDenied, variants, variantsFilter }) => {
    const t = useTypedTranslationFunction();
    const [variantFilterState] = useVariantFilterState();
    const [areVariantsExpanded, setAreVariantsExpanded] = useState(false);
    const filteredVariants = filterVariantsBySelectedParameters(variants, variantFilterState);

    return (
        <>
            <div className="mb-5 w-full">
                <Heading type="h3">{t('Product variants')}</Heading>
                <ul className="flex w-full flex-col">
                    {filteredVariants
                        .slice(0, areVariantsExpanded ? undefined : DEFAULT_NUMBER_OF_SHOWN_VARIANTS)
                        .map((variant, index) => (
                            <Variant
                                key={variant.uuid}
                                variant={variant}
                                isSellingDenied={isSellingDenied}
                                gtmListName="variants"
                                listIndex={index}
                                variantsFilter={variantsFilter}
                            />
                        ))}
                </ul>
                {filteredVariants.length > DEFAULT_NUMBER_OF_SHOWN_VARIANTS && (
                    <Button
                        size="small"
                        className="mx-auto mt-3 hover:bg-grey hover:text-white"
                        variant="secondary"
                        onClick={() => setAreVariantsExpanded(!areVariantsExpanded)}
                    >
                        {areVariantsExpanded ? t('show less') : t('show more')}
                    </Button>
                )}
            </div>
        </>
    );
};
