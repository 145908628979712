import { Icon } from 'components/Basic/Icon/Icon';
import { mobileFirstSizes } from 'components/Theme/mediaQueries';
import { useComponentUpdate } from 'hooks/helpers/useComponentUpdate';
import { useLatest } from 'hooks/ui/useLatest';
import { useMediaMin } from 'hooks/ui/useMediaMin';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { twJoin } from 'tailwind-merge';

export type ButtonWrapperType = {
    buttonAlign?: 'left' | 'center';
};

export type LimitHeightType = {
    mobileHeight: number;
    desktopHeight?: number;
};

export type LimitHeightWrapType = LimitHeightType &
    ButtonWrapperType & {
        buttonLabelLess?: string;
        buttonLabelMore: string;
        desktopSizeDelimiter?: number;
        increaseParentCounter?: () => void;
        updateDependency?: string | number;
    };

const LimitHeightWrap: FC<LimitHeightWrapType> = ({
    increaseParentCounter,
    buttonAlign,
    buttonLabelLess,
    buttonLabelMore,
    children,
    desktopHeight,
    desktopSizeDelimiter,
    mobileHeight,
    updateDependency,
}) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isEnabled, setIsEnabled] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const onClickHandler = useCallback(() => {
        setIsCollapsed((prev) => {
            if (increaseParentCounter) {
                increaseParentCounter();
            }

            return !prev;
        });
    }, [increaseParentCounter]);

    const handleEnabled = useCallback(
        (windowWidth: number) => {
            setIsEnabled(() => {
                const node = wrapperRef.current;
                if (!node) {
                    return false;
                }
                const defaultSizeDelimiter = desktopSizeDelimiter ?? mobileFirstSizes.vl;
                if (windowWidth <= defaultSizeDelimiter) {
                    return node.scrollHeight > mobileHeight;
                } else if (desktopHeight === undefined) {
                    return false;
                }

                return node.scrollHeight > desktopHeight;
            });
        },
        [desktopSizeDelimiter, mobileHeight, desktopHeight],
    );

    const latestHandleEnabled = useLatest(handleEnabled);

    useEffect(() => {
        const handler = () => latestHandleEnabled.current?.(window.innerWidth);
        handler();
        window.addEventListener('resize', handler, { passive: true });

        return () => {
            window.removeEventListener('resize', handler);
        };
    }, [latestHandleEnabled]);

    useComponentUpdate(() => {
        latestHandleEnabled.current?.(window.innerWidth);
        setIsCollapsed(true);
    }, [latestHandleEnabled, updateDependency]);

    const isDesktop = useMediaMin('vl');
    const collapsedHeight = isDesktop ? (desktopHeight !== undefined ? `${desktopHeight}px` : 'auto') : mobileHeight;

    return (
        <>
            {isEnabled && isCollapsed ? (
                <div
                    className="limit-height line-clamp-2 overflow-hidden text-ellipsis"
                    style={{ height: collapsedHeight }}
                    ref={wrapperRef}
                >
                    {children}
                </div>
            ) : (
                <div className="limit-height h-auto overflow-visible after:hidden" ref={wrapperRef}>
                    {children}
                </div>
            )}
            {isEnabled && (isCollapsed || buttonLabelLess) && (
                <div
                    className={
                        buttonAlign !== 'left' ? 'z-two relative bottom-0 -translate-y-9 text-center' : undefined
                    }
                >
                    <button
                        className="mt-2 flex h-fit items-center justify-start gap-1 text-sm font-bold text-primary"
                        onClick={onClickHandler}
                    >
                        {isCollapsed ? buttonLabelMore : buttonLabelLess}
                        <Icon
                            className={twJoin('h-3 w-3 text-greyLight transition', !isCollapsed && 'rotate-180')}
                            icon="Arrow"
                            iconType="icon"
                        />
                    </button>
                </div>
            )}
        </>
    );
};

export default LimitHeightWrap;
