import { styled } from 'components/Theme/main';
import { css } from 'styled-components';

const localVariables = {
    userTextUlPointSize: '5px',
    userTextHeadingFontSize: '18px',
    userTextHeadingMarginBottom: '0.4rem',
    userTextParagraphMargin: '0 0 1.75rem',
    userTextComponentMargin: '0 0 1.75rem',
    userTextFontSize: '15px',
    userTextLineHeight: '1.6',
} as const;

export const UserTextStyled = styled.section(
    ({ theme }) => css`
        font-size: ${localVariables.userTextFontSize};
        line-height: ${localVariables.userTextLineHeight};
        -webkit-font-smoothing: antialiased;

        color: ${theme.color.greyLight};

        p,
        ul,
        ol,
        li {
            line-height: inherit;

            font-size: inherit;
            color: inherit;
        }

        h2,
        h3,
        h4,
        h5 {
            margin-bottom: ${localVariables.userTextHeadingMarginBottom};
            text-transform: none;

            font-size: ${localVariables.userTextHeadingFontSize};
        }

        p {
            margin: ${localVariables.userTextParagraphMargin};

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        strong,
        b {
            color: ${theme.color.base};
            font-weight: 700;
        }

        a {
            color: ${theme.color.primary};
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        ol {
            list-style: decimal inside;
        }

        ul {
            margin: 10px 0px;
            list-style: disc inside;
        }

        img {
            display: block;
            height: auto;

            border-radius: ${theme.radius.big};
        }
    `,
);
