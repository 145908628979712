import { ProductDetailImageSlider } from './ProductDetailImageSlider';
import { Image } from 'components/Basic/Image/Image';
import { ProductFlags } from 'components/Blocks/Product/Flags/ProductFlags';
import { isElementVisible } from 'components/Helpers/isElementVisible';
import { desktopFirstSizes } from 'components/Theme/mediaQueries';
import { ProductDetailImagesFragmentApi, useProductImagesQueryApi } from 'graphql/generated';
import { getUrlWithoutGetParameters } from 'helpers/parsing/getUrlWithoutGetParameters';
import { useGetWindowSize } from 'hooks/ui/useGetWindowSize';
import { useResizeWidthEffect } from 'hooks/ui/useResizeWidthEffect';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import LightGallery from 'lightgallery/react';
import { useRouter } from 'next/router';
import { FC, useState } from 'react';
import { twJoin } from 'tailwind-merge';
import { SimpleFlagType } from 'types/flag';
import { ImageType } from 'types/image';

type ProductDetailGalleryProps = {
    images: ImageType[];
    productName: string;
    flags: SimpleFlagType[];
    mainVariantSlugOfVariant?: string | null;
};

const MAX_VISIBLE_ITEMS = 4;

export const ProductDetailGallery: FC<ProductDetailGalleryProps> = ({
    flags,
    images,
    productName,
    mainVariantSlugOfVariant,
}) => {
    const [isSliderVisible, setSliderVisibility] = useState(false);
    const { width } = useGetWindowSize();
    const router = useRouter();
    const slug = getUrlWithoutGetParameters(router.asPath);

    const [{ data: variantImagesData }] = useProductImagesQueryApi({
        variables: { slug },
        pause: mainVariantSlugOfVariant ? router.asPath.includes(mainVariantSlugOfVariant) : true,
    });

    const variantImages = (variantImagesData?.slug as ProductDetailImagesFragmentApi | undefined)?.images;

    useResizeWidthEffect(
        width,
        desktopFirstSizes.notLargeDesktop,
        () => setSliderVisibility(false),
        () => setSliderVisibility(true),
        () => setSliderVisibility(isElementVisible([{ min: 0, max: desktopFirstSizes.notLargeDesktop }], width)),
    );

    if (images.length === 0) {
        return null;
    }

    const mainImage = variantImages?.[0] ?? images[0];
    const mainImageUrl = mainImage.sizes?.find((size) => size.size === 'default')?.url;
    const isProductHasMoreImageThanLimit = images.length > MAX_VISIBLE_ITEMS + 2;

    const getGalleryImages = () => {
        const variantMainImageUrl = variantImages?.[0].sizes[0].url;

        if (variantMainImageUrl) {
            return images.filter((images) => images.sizes?.[0].url !== variantMainImageUrl);
        }

        return images.slice(1);
    };

    const galleryImages = getGalleryImages();

    return isSliderVisible ? (
        <ProductDetailImageSlider galleryItems={[mainImage, ...galleryImages]} flags={flags} />
    ) : (
        <LightGallery mode="lg-fade" thumbnail plugins={[lgThumbnail]} selector=".lightboxItem">
            <div
                data-src={mainImageUrl}
                className="lightboxItem mr-6 hidden h-[450px] w-full items-center justify-center bg-white vl:relative vl:order-1 vl:flex vl:overflow-hidden vl:rounded-xl"
            >
                <Image
                    image={mainImage}
                    alt={productName}
                    type="default"
                    className="vl:!max-h-[350px] vl:!max-w-[350px]"
                />
                <div className="absolute left-4 top-3 flex flex-col">
                    <ProductFlags flags={flags} />
                </div>
            </div>
            <div className="hidden vl:relative vl:order-none vl:mb-5 vl:flex vl:w-24 vl:flex-col vl:pr-6">
                {galleryImages.map((image, index) => (
                    <div
                        key={index}
                        className={twJoin(
                            'lightboxItem relative block h-20 w-20 cursor-pointer vl:mb-3 vl:rounded-md vl:bg-white vl:p-2 vl:transition vl:hover:bg-greyLighter',
                            index > MAX_VISIBLE_ITEMS && 'hidden',
                            isProductHasMoreImageThanLimit &&
                                index === MAX_VISIBLE_ITEMS &&
                                'flex items-center justify-center font-bold',
                        )}
                        data-src={image.sizes?.find((size) => size.size === 'default')?.url}
                    >
                        <Image
                            image={image}
                            alt={productName}
                            type="default"
                            className={twJoin(
                                '!max-h-16 !max-w-[64px] vl:absolute vl:bottom-0 vl:left-0 vl:right-0 vl:top-0 vl:m-auto vl:h-auto vl:max-h-full vl:w-auto vl:max-w-full vl:mix-blend-multiply',
                                isProductHasMoreImageThanLimit && index === MAX_VISIBLE_ITEMS && 'opacity-30',
                            )}
                        />
                        {isProductHasMoreImageThanLimit && index === MAX_VISIBLE_ITEMS && (
                            <span>+ {images.length - MAX_VISIBLE_ITEMS - 1}</span>
                        )}
                    </div>
                ))}
            </div>
        </LightGallery>
    );
};
