import { ProductDetailAccessories } from './ProductDetailAccessories/ProductDetailAccessories';
import {
    ProductDetail,
    ProductDetailHeading,
    ProductDetailImage,
    ProductDetailInfo,
    ProductDetailPrefix,
} from './ProductDetailElements';
import { ProductDetailGallery } from './ProductDetailGallery';
import { ProductDetailRelated } from './ProductDetailRelated/ProductDetailRelated';
import { ProductVariantsTable } from './ProductVariantsTable/ProductVariantsTable';
import { ProductMetadata } from 'components/Basic/Head/ProductMetadata/ProductMetadata';
import { Heading } from 'components/Basic/Heading/Heading';
import { Table } from 'components/Basic/Table/Table';
import { VariantsFilter } from 'components/Blocks/Product/VariantsFilter/VariantsFilter';
import { VariantFilterProvider } from 'components/Blocks/Product/VariantsFilter/VariantsFilterContext/VariantFilterProvider';
import { UserText } from 'components/Helpers/UserText/UserText';
import { Webline } from 'components/Layout/Webline/Webline';
import { getUrlWithoutGetParameters } from 'helpers/parsing/getUrlWithoutGetParameters';
import { useGtmProductDetailView } from 'hooks/gtm/useGtmProductDetailView';
import { useTypedTranslationFunction } from 'hooks/typescript/useTypedTranslationFunction';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { ProductParameterType } from 'types/parameter';
import { MainVariantDetailType } from 'types/product';

type ProductDetailMainVariantContentProps = {
    product: MainVariantDetailType;
    fetching: boolean;
    mainVariantSlugOfVariant: string | null | undefined;
};

const TEST_IDENTIFIER = 'pages-productdetail-';

export const ProductDetailMainVariantContent: FC<ProductDetailMainVariantContentProps> = ({
    product,
    fetching,
    mainVariantSlugOfVariant,
}) => {
    const router = useRouter();
    const { images, name, parameters, nameSuffix, namePrefix, variants, isSellingDenied, shortDescription } = product;
    useGtmProductDetailView(product, getUrlWithoutGetParameters(router.asPath), fetching);

    const formatParameterValue = (valueText: string, index: number) => {
        return index > 0 ? ' | ' + valueText : valueText;
    };

    const t = useTypedTranslationFunction();

    const filteredVariantParameters = mapProductParameters(parameters).filter((parameter) => parameter.isVariant);

    return (
        <>
            <ProductMetadata product={product} />
            <VariantFilterProvider productFilterOptions={{ parameters: filteredVariantParameters }}>
                <Webline>
                    <ProductDetail>
                        <ProductDetailImage data-testid={TEST_IDENTIFIER + 'gallery'}>
                            <ProductDetailGallery
                                images={images}
                                productName={name}
                                flags={[]}
                                mainVariantSlugOfVariant={mainVariantSlugOfVariant}
                            />
                        </ProductDetailImage>
                        <ProductDetailInfo>
                            <ProductDetailPrefix dataTestId={TEST_IDENTIFIER + 'prefix'}>
                                {namePrefix}
                            </ProductDetailPrefix>
                            <ProductDetailHeading dataTestId={TEST_IDENTIFIER + 'name'}>
                                {name} {nameSuffix}
                            </ProductDetailHeading>
                            <div className="mb-5 hidden vl:block" data-testid={TEST_IDENTIFIER + 'short-description'}>
                                {shortDescription}
                            </div>
                            <VariantsFilter
                                variantParameters={filteredVariantParameters}
                                variants={variants}
                                mainVariantSlugOfVariant={mainVariantSlugOfVariant}
                            />
                            {product.staticFeaturesAttributesDescription && (
                                <UserText htmlContent={product.staticFeaturesAttributesDescription} />
                            )}
                        </ProductDetailInfo>
                    </ProductDetail>
                </Webline>
                <Webline dataTestId={TEST_IDENTIFIER + 'variants'}>
                    <ProductVariantsTable
                        variants={variants}
                        isSellingDenied={isSellingDenied}
                        variantsFilter={filteredVariantParameters}
                    />
                </Webline>
                <Webline dataTestId={TEST_IDENTIFIER + 'description'} innerClassName="flex flex-col vl:flex-row gap-6">
                    <div data-testid={TEST_IDENTIFIER + 'overview-content'}>
                        <UserText htmlContent={product.description} />
                    </div>
                    {product.parameters.length > 0 && (
                        <div data-testid={TEST_IDENTIFIER + 'parameters-content'} className="vl:flex-[0_0_40%]">
                            <Heading type="h2">{t('Parameters')}</Heading>
                            <Table>
                                <tbody>
                                    {product.parameters.map((parameter) => (
                                        <tr
                                            key={parameter.uuid}
                                            className="!border-t-0 vl:!border-t vl:!border-greyLighter first:vl:!border-t-0"
                                        >
                                            <th className="py-2 text-left text-sm font-bold uppercase leading-5">
                                                {parameter.name}
                                            </th>
                                            <td className="w-2/3 py-2 text-right text-sm leading-5">
                                                {parameter.values.map((value, index) =>
                                                    formatParameterValue(value.text, index),
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    )}
                </Webline>
                <Webline innerClassName="flex flex-col gap-6 mt-6">
                    <ProductDetailAccessories accessories={product.accessories} />
                </Webline>
                <Webline innerClassName="flex flex-col gap-6 mt-8">
                    <ProductDetailRelated relatedProducts={product.relatedProducts} />
                </Webline>
            </VariantFilterProvider>
        </>
    );
};

const mapProductParameters = (parameters: ProductParameterType[]): ProductParameterType[] => {
    return parameters.map((parameter) => ({
        __typename: parameter.__typename,
        visible: parameter.visible,
        name: parameter.name,
        uuid: parameter.uuid,
        values: parameter.values.map((parameterValue) => ({
            __typename: parameterValue.__typename,
            checked: false,
            uuid: parameterValue.uuid,
            text: parameterValue.text,
            rgbHex: null,
        })),
        minimalValue: null,
        maximalValue: null,
        unit: null,
        isCollapsed: false,
        isVariant: parameter.isVariant,
        selectedValue: null,
    }));
};
