import { VariantsFilterContext } from './context';
import { filterVariantReducer } from './reducer';
import { FC, useReducer } from 'react';
import { ProductParameterType } from 'types/parameter';
import {
    FilterFormParameterType,
    FilterFormParameterValuesType,
    FilterOptionsType,
} from 'types/productVariantParameter';

type VariantFilterProviderProps = {
    productFilterOptions: FilterOptionsType;
};
export const VariantFilterProvider: FC<VariantFilterProviderProps> = ({ children, productFilterOptions }) => {
    const value = useReducer(filterVariantReducer, {
        options: productFilterOptions,
        selected: { parameters: getDefaultParameterValues(productFilterOptions) },
    });

    return <VariantsFilterContext.Provider value={value}>{children}</VariantsFilterContext.Provider>;
};

const getDefaultParameterValues = (productFilterOptions: FilterOptionsType): FilterFormParameterType[] => {
    if (productFilterOptions.parameters === undefined) {
        return [];
    }

    const getValues = (parameter: ProductParameterType): FilterFormParameterValuesType[] => {
        if (!('values' in parameter)) {
            return [];
        }

        return parameter.values.map((value) => {
            return {
                uuid: value.uuid,
                text: value.text,
            };
        });
    };

    return productFilterOptions.parameters.map((parameter) => {
        return {
            parameterName: parameter.name,
            parameterUuid: parameter.uuid,
            values: getValues(parameter),
            selectedValue: null,
            isVariant: parameter.isVariant,
        };
    });
};
