import { FilterActions, FilterCallbacks, FilterState } from './types';
import produce from 'immer';
import { Reducer } from 'react';

const setVariantParameter = (state: FilterState, payload: FilterCallbacks['setVariantParameter']): FilterState => {
    const { value, parameterIndex } = payload;
    return produce(state, (draft) => {
        const selectedParameterValues = state.selected.parameters[parameterIndex].values;
        draft.selected.parameters[parameterIndex].selectedValue = selectedParameterValues.findIndex(
            (item) => item.uuid === value.uuid,
        );
    });
};

const resetAllParameters = (state: FilterState): FilterState => {
    return produce(state, (draft) => {
        draft.selected.parameters =
            state.options.parameters?.map((parameter) => ({
                parameterName: parameter.name,
                selectedValue: null,
                parameterUuid: parameter.uuid,
                isVariant: parameter.isVariant,
                values: parameter.values,
            })) ?? [];
    });
};

export const filterVariantReducer: Reducer<FilterState, FilterActions> = (state, action): FilterState => {
    switch (action.type) {
        case 'setVariantParameter':
            return setVariantParameter(state, action.payload);
        case 'resetAllParameters':
            return resetAllParameters(state);
        default:
            return state;
    }
};
