import { Image } from 'components/Basic/Image/Image';
import { Loader } from 'components/Basic/Loader/Loader';
import { AddToCartPopup } from 'components/Blocks/Product/AddToCartPopup/AddToCartPopup';
import { ProductFlags } from 'components/Blocks/Product/Flags/ProductFlags';
import { VariantParametersList } from 'components/Blocks/Product/VariantsParametersList/VariantsParametersList';
import { Button } from 'components/Forms/Button/Button';
import { Spinbox } from 'components/Forms/Spinbox/Spinbox';
import { ProductDetailCode } from 'components/Pages/ProductDetail/ProductDetailElements';
import { mapAddToCartPopupData } from 'connectors/cart/Cart';
import { useAddToCart } from 'hooks/cart/useAddToCart';
import { useFormatPrice } from 'hooks/formatting/useFormatPrice';
import { useTypedTranslationFunction } from 'hooks/typescript/useTypedTranslationFunction';
import Trans from 'next-translate/Trans';
import { FC, useRef, useState } from 'react';
import { useShopsysSelector } from 'redux/main';
import { twJoin } from 'tailwind-merge';
import { AddToCartPopupDataType } from 'types/cart';
import { GtmListNameType } from 'types/gtm';
import { ProductParameterType } from 'types/parameter';
import { ListedVariantType } from 'types/product';
import { twMergeCustom } from 'utils/twMerge';

type VariantProps = {
    variant: ListedVariantType;
    isSellingDenied: boolean;
    gtmListName: GtmListNameType;
    listIndex: number;
    variantsFilter: ProductParameterType[];
};

const TEST_IDENTIFIER = 'pages-productdetail-variant-';

export const Variant: FC<VariantProps> = ({ gtmListName, isSellingDenied, listIndex, variant, variantsFilter }) => {
    const formatPrice = useFormatPrice();
    const t = useTypedTranslationFunction();
    const spinboxRef = useRef<HTMLInputElement | null>(null);
    const [changeCartItemQuantity, fetching] = useAddToCart(gtmListName);
    const [popupData, setPopupData] = useState<AddToCartPopupDataType | null>(null);
    const { currencyCode } = useShopsysSelector((state) => state.domain);

    const onAddToCartHandler = async () => {
        if (spinboxRef.current === null) {
            return;
        }

        const addToCartResult = await changeCartItemQuantity(
            variant.uuid,
            spinboxRef.current.valueAsNumber,
            gtmListName,
            listIndex,
        );
        spinboxRef.current!.valueAsNumber = variant.minimumPurchaseQuantity;
        setPopupData(mapAddToCartPopupData(addToCartResult, currencyCode));
    };

    return (
        <>
            <li
                key={variant.uuid}
                data-testid={TEST_IDENTIFIER + variant.catalogNumber}
                className={twJoin(
                    'flex w-full flex-wrap content-center justify-between gap-x-2 gap-y-4 p-3 first:border-t vl:grid vl:grid-cols-[2fr_1fr_1fr_1fr_1fr] vl:gap-2',
                    !variant.isAvailableAtJafholzEshop && 'border-b border-greyLighter',
                )}
            >
                <Cell className="w-3/4 gap-6 vl:w-auto">
                    <div className="flex aspect-square items-center justify-center rounded-md bg-white p-1">
                        <Image
                            className="!max-h-16 !max-w-[64px]"
                            alt={variant.fullName}
                            type="default"
                            image={variant.image}
                        />
                    </div>
                    <div className="flex h-full flex-col justify-between">
                        <span className="text-sm font-bold">{variant.fullName}</span>
                        {!!variant.parameters.length && (
                            <VariantParametersList parameters={variant.parameters} variantsFilter={variantsFilter} />
                        )}
                        {!!variant.flags.length && (
                            <div className="flex flex-wrap gap-1">
                                <ProductFlags className="mr-0" flags={variant.flags} />
                            </div>
                        )}
                        <ProductDetailCode dataTestId={TEST_IDENTIFIER + 'code'} className="mb-0">
                            {t('Code')}: {variant.catalogNumber}
                        </ProductDetailCode>
                    </div>
                </Cell>
                <Cell
                    className="w-[calc(25%-8px)] cursor-pointer flex-col items-end vl:w-auto vl:items-center vl:justify-center vl:text-center"
                    dataTestId={TEST_IDENTIFIER + 'availability'}
                >
                    <div
                        className={twJoin(
                            'flex items-center text-sm font-bold no-underline hover:no-underline',
                            variant.availability.status === 'in-stock' && 'text-inStock hover:text-inStock',
                            variant.availability.status === 'out-of-stock' && 'text-red hover:text-red',
                        )}
                    >
                        {variant.availability.name}
                    </div>
                </Cell>
                <Cell className="justify-center" dataTestId={TEST_IDENTIFIER + 'price'}>
                    <Spinbox
                        step={variant.minimumPurchaseQuantity}
                        min={variant.minimumPurchaseQuantity}
                        max={variant.stockQuantity}
                        defaultValue={variant.minimumPurchaseQuantity}
                        ref={spinboxRef}
                    />
                </Cell>
                <Cell className="justify-center whitespace-nowrap" dataTestId={TEST_IDENTIFIER + 'price'}>
                    <span className="mr-2 font-bold text-primary">
                        {formatPrice(variant.price.priceWithVat * variant.minimumPurchaseQuantity)}
                    </span>
                    {`${t('for {{ amount }} pcs', { amount: variant.minimumPurchaseQuantity })} ${t('without VAT')}`}
                </Cell>
                <Cell className="w-full justify-end">
                    {isSellingDenied ? (
                        <>{t('This item can no longer be purchased')}</>
                    ) : (
                        <div className="flex justify-between lg:justify-around">
                            <Button
                                type="button"
                                className="relative w-40"
                                name="add-to-cart"
                                onClick={onAddToCartHandler}
                                dataTestId={TEST_IDENTIFIER}
                                isDisabled={variant.isSellingDenied}
                            >
                                {fetching && (
                                    <Loader className="absolute left-4 top-1/2 h-4 w-4 -translate-y-1/2 text-white" />
                                )}
                                <span>{t('Add to cart')}</span>
                            </Button>
                        </div>
                    )}
                </Cell>
            </li>
            {variant.isAvailableAtJafholzEshop && (
                <li className="border-b border-greyLighter">
                    <div className="mb-3 rounded-lg bg-orangeLight p-2">
                        <Trans
                            i18nKey="This product is available at the <link>JAF HOLZ Online Shop</link>"
                            components={{
                                link: <a href={variant.jafholzEshopUrl ?? '/'} target="_blank" rel="noreferrer" />,
                            }}
                        />
                    </div>
                </li>
            )}
            {popupData !== null && (
                <AddToCartPopup isVisible onCloseCallback={() => setPopupData(null)} product={popupData} />
            )}
        </>
    );
};

type CellProps = { className?: string; dataTestId?: string; onClick?: () => void };

const Cell: FC<CellProps> = ({ className, children, dataTestId, onClick }) => (
    <div className={twMergeCustom('flex items-center text-left', className)} data-testid={dataTestId} onClick={onClick}>
        {children}
    </div>
);
