import { ListItemWithImage } from 'components/Blocks/SimpleNavigation/ListItem/ListItemWithImage';
import { SimpleNavigation } from 'components/Blocks/SimpleNavigation/SimpleNavigation';
import { useMediaMin } from 'hooks/ui/useMediaMin';
import { useKeenSlider } from 'keen-slider/react';
import { FC } from 'react';
import { ListedItemPropType } from 'types/simpleNavigation';

type CategoryDetailSubcategoriesProps = {
    subCategories: ListedItemPropType[];
};

export const CategoryDetailSubcategories: FC<CategoryDetailSubcategoriesProps> = ({ subCategories }) => {
    const isDesktop = useMediaMin('lg');
    const [sliderRef] = useKeenSlider<HTMLDivElement>({
        slidesPerView: 2.9,
        spacing: 15,
    });

    if (!subCategories.length) {
        return null;
    }

    return (
        <>
            {isDesktop ? (
                <SimpleNavigation className="mb-8 lg:mb-6" withImage listedItems={subCategories} />
            ) : (
                <div className="keen-slider mb-8 lg:mb-6" ref={sliderRef}>
                    {subCategories.map((subCategory, index) => (
                        <div className="keen-slider__slide" key={index}>
                            <ListItemWithImage listedItem={subCategory} />
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};
